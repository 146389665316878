import { createStylesParam } from '@wix/tpa-settings';
import postStyleParams from '@app/components/Post/stylesParams';

const styleParamsForFullPostLayoutObject = {
  'post-textColor': postStyleParams.textParagraphColor,
  'post-pageFont': postStyleParams.textParagraphFont,
  'post-linkHashtagColor': postStyleParams.textLinksAndHashtagsColor,
  'post-quotesColor': postStyleParams.textQuoteColor,
  'post-quotesFont': postStyleParams.textQuoteFont,
  'post-titleColor': postStyleParams.textH1Color,
  'post-titleFont': postStyleParams.textH1Font,
  'post-headerTwoColor': postStyleParams.textH2Color,
  'post-headerTwoFont': postStyleParams.textH2Font,
  'post-headerThreeColor': postStyleParams.textH3Color,
  'post-headerThreeFont': postStyleParams.textH3Font,
  'post-headerFourColor': postStyleParams.textH4Color,
  'post-headerFourFont': postStyleParams.textH4Font,
  'post-headerFiveColor': postStyleParams.textH5Color,
  'post-headerFiveFont': postStyleParams.textH5Font,
  'post-headerSixColor': postStyleParams.textH6Color,
  'post-headerSixFont': postStyleParams.textH6Font,
  'post-cardBackgroundColor': postStyleParams.postBackgroundColor,
  'post-cardBorderWidth': postStyleParams.postBorderWidth,
  'post-cardBorderColor': postStyleParams.postBorderColor,
  'post-ratingUnfilledStarColor': postStyleParams.ratingsStarEmptyColor,
  'post-ratingFilledStarColor': postStyleParams.ratingsStarFilledColor,
  'post-ratingFont': postStyleParams.ratingsTextColor,
  'post-ratingFontColor': postStyleParams.ratingsTextFont,
};

export type IStylesParams = typeof styleParamsForFullPostLayoutObject;

export default Object.fromEntries(
  Object.entries(styleParamsForFullPostLayoutObject).map(([key, styleParam]) => [
    key,
    createStylesParam(key, styleParam),
  ]),
);
